import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "Choose language",
  "subtitle": "How to specify the language used by the user interface (where supported)"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`By default, Criipto Verify uses the `}<em parentName="p">{`Accept-Language`}</em>{` HTTP request header to figure out which language the user prefers. `}</p>
    <p>{`You can override this per authentication request by specifying a `}<inlineCode parentName="p">{`ui_locales`}</inlineCode>{` query parameter. This works for both OpenID Connect and WS-Federation, even if WS-Federation does not have protocol support for per-request language selection.`}</p>
    <p>{`The actual support for controlling the language varies by eID method`}</p>
    <ol>
      <li parentName="ol">{`Danish MitID: `}<inlineCode parentName="li">{`da`}</inlineCode>{` and `}<inlineCode parentName="li">{`en`}</inlineCode>{` supported. `}</li>
      <li parentName="ol">{`Norwegian BankID: `}<inlineCode parentName="li">{`nb`}</inlineCode>{` and `}<inlineCode parentName="li">{`en`}</inlineCode>{` supported.`}</li>
      <li parentName="ol">{`Norwegian Vipps Login: Language is controlled by user in the app.`}</li>
      <li parentName="ol">{`Swedish BankID: `}<inlineCode parentName="li">{`sv`}</inlineCode>{` and `}<inlineCode parentName="li">{`en`}</inlineCode>{` supported.`}</li>
      <li parentName="ol">{`Finnish BankID: `}<inlineCode parentName="li">{`fi`}</inlineCode>{`, `}<inlineCode parentName="li">{`se`}</inlineCode>{` and `}<inlineCode parentName="li">{`en`}</inlineCode>{` supported.`}</li>
      <li parentName="ol">{`Dutch DigiD: User chooses language in the UI presented by Logius.`}</li>
    </ol>
    <p>{`Additionally, the following syntax is supported: `}<inlineCode parentName="p">{`da-DK`}</inlineCode>{`|`}<inlineCode parentName="p">{`sv-SE`}</inlineCode>{`|`}<inlineCode parentName="p">{`nb-NO`}</inlineCode>{`|`}<inlineCode parentName="p">{`en-US`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      